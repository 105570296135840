/* https://gwfh.mranftl.com/ */
/* be-vietnam-pro-700 - latin */
@font-face {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: bold;
  font-display: fallback;
  src: url('./be-vietnam-pro-v10/be-vietnam-pro-v10-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./be-vietnam-pro-v10/be-vietnam-pro-v10-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./be-vietnam-pro-v10/be-vietnam-pro-v10-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('./be-vietnam-pro-v10/be-vietnam-pro-v10-latin-700.woff') format('woff'), /* Modern Browsers */
       url('./be-vietnam-pro-v10/be-vietnam-pro-v10-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./be-vietnam-pro-v10/be-vietnam-pro-v10-latin-700.svg#BeVietnamPro') format('svg'); /* Legacy iOS */
}
