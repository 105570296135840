/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 15, 2023 */

@font-face {
  font-family: 'pp_neue_montreal';
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
  src: url('./neue-montreal/ppneuemontreal-medium-webfont.eot');
  src: url('./neue-montreal/ppneuemontreal-medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('./neue-montreal/ppneuemontreal-medium-webfont.woff2') format('woff2'),
       url('./neue-montreal/ppneuemontreal-medium-webfont.woff') format('woff'),
       url('./neue-montreal/ppneuemontreal-medium-webfont.ttf') format('truetype'),
       url('./neue-montreal/ppneuemontreal-medium-webfont.svg#pp_neue_montrealmedium') format('svg');
}