/* dm-mono-300 - latin */
@font-face {
  font-display: fallback;
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: 300;
  src: url('./dm-mono-v10/dm-mono-v10-latin-300.eot'); /* IE9 Compat Modes */
  src: url('./dm-mono-v10/dm-mono-v10-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./dm-mono-v10/dm-mono-v10-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('./dm-mono-v10/dm-mono-v10-latin-300.woff') format('woff'), /* Modern Browsers */
       url('./dm-mono-v10/dm-mono-v10-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./dm-mono-v10/dm-mono-v10-latin-300.svg#DMMono') format('svg'); /* Legacy iOS */
}
